class Select {
  constructor(selectElement) {
    this.element = selectElement;
    this.defaultSelect = selectElement.querySelector('select');
    this.state = {};

    this.initState();
    this.initTemplate();
    this.attachEvents();
  }

  initState() {
    this.state.options = this.getOptionsData();
    this.state.optionsVisibilityState = 'hidden';
  }

  getOptionsData() {
    this.defaultSelectOptions = Array.from(this.defaultSelect.options);
    return this.defaultSelectOptions.map((option, index) => {
      const optionValue = option.value;
      const optionTitle = option.textContent;
      const optionSelectedState = option.selected ? 'selected' : 'unselected';

      return {
        optionValue,
        optionTitle,
        optionSelectedState,
        optionIndex: index,
      };
    });
  }

  getSelectedOptions() {
    return this.state.options.filter((option) => option.optionSelectedState === 'selected');
  }

  initTemplate() {
    this.component = document.createElement('div');
    this.togglerElement = this.createTogglerElement();
    this.optionsContainerElement = this.createOptions();

    this.component.append(this.togglerElement);
    this.component.append(this.optionsContainerElement);
    this.element.append(this.component);
  }

  createTogglerElement() {
    const toggler = document.createElement('div');
    const value = this.createValueElement();
    toggler.classList.add('select__toggler');
    toggler.append(value);
    return toggler;
  }

  createValueElement() {
    this.valueElement = document.createElement('div');
    const selectCurrentValue = this.getSelectedOptions()
      .map((selectedOption) => selectedOption.optionTitle)
      .join(', ');
    this.valueElement.classList.add('select__value');
    this.valueElement.textContent = selectCurrentValue;
    return this.valueElement;
  }

  createOptions() {
    const optionsContainer = document.createElement('div');
    this.optionsElements = this.state.options
      .map((option) => {
        const optionElement = document.createElement('div');
        const optionClasses = option.optionSelectedState === 'selected' ? ['select__option', 'select__option_selected'] : ['select__option'];
        optionElement.classList.add(...optionClasses);
        optionElement.textContent = option.optionTitle;
        return optionElement;
      });

    optionsContainer.classList.add('select__options');
    optionsContainer.append(...this.optionsElements);

    return optionsContainer;
  }

  renderToggler() {
    if (this.state.optionsVisibilityState === 'hidden') {
      this.togglerElement.classList.remove('select__toggler_expanded');
    }

    if (this.state.optionsVisibilityState === 'shown') {
      this.togglerElement.classList.add('select__toggler_expanded');
    }
  }

  renderValue() {
    const selectedOptionsTitles = this.getSelectedOptions()
      .map((option) => option.optionTitle)
      .join(', ');
    this.valueElement.textContent = selectedOptionsTitles;
  }

  renderOptionsList() {
    if (this.state.optionsVisibilityState === 'hidden') {
      this.optionsContainerElement.classList.remove('select__options_shown');
    }

    if (this.state.optionsVisibilityState === 'shown') {
      this.optionsContainerElement.classList.add('select__options_shown');
    }
  }

  renderOptions() {
    this.state.options.forEach((option, index) => {
      const optionsElement = this.optionsElements[index];

      if (option.optionSelectedState === 'selected') {
        optionsElement.classList.add('select__option_selected');
      }

      if (option.optionSelectedState === 'unselected') {
        optionsElement.classList.remove('select__option_selected');
      }
    });
  }

  renderDefaultSelect() {
    this.state.options.forEach((option, index) => {
      const defaultSelectOption = this.defaultSelectOptions[index];

      if (option.optionSelectedState === 'selected') {
        defaultSelectOption.setAttribute('selected', true);
      }

      if (option.optionSelectedState === 'unselected') {
        defaultSelectOption.removeAttribute('selected');
      }
    });
  }

  togglerClickHandler() {
    this.state.optionsVisibilityState = this.state.optionsVisibilityState === 'hidden' ? 'shown' : 'hidden';

    this.renderToggler();
    this.renderOptionsList();
  }

  optionClickHandler(optionElementIndex) {
    this.state.options = this.state.options
      .map((option, index) => {
        const optionSelectedStateUpdated = index === optionElementIndex ? 'selected' : 'unselected';

        return {
          ...option,
          optionSelectedState: optionSelectedStateUpdated,
        };
      });
    this.state.optionsVisibilityState = 'hidden';

    this.renderValue();
    this.renderOptions();
    this.renderToggler();
    this.renderOptionsList();
    this.renderDefaultSelect();
  }

  attachEvents() {
    this.togglerElement.addEventListener('click', this.togglerClickHandler.bind(this));

    this.optionsElements.forEach((optionElement, optionElementIndex) => {
      optionElement.addEventListener('click', this.optionClickHandler.bind(this, optionElementIndex));
    });
  }
}

export default Select;
