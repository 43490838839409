import './components/page/page.js';
import './components/logo/logo.js';
import './components/header/header.js';
import './components/events-tile/events-tile.js';
import './components/anons-card/anons-card.js';
import './components/faq/faq.js';
import './components/events-calendar/events-calendar.js';
import './components/calendar-card/calendar-card.js';
import './components/contacts/contacts.js';
import './components/banner/banner.js';
import './components/cover/cover.js';

import initEventsSlider from './components/events-slider/events-slider.js';
import initDetails from './components/detail/detail.js';
import initEventSlider from './components/event-slider/event-slider.js';
import initCollapse from './components/collapse/collapse.js';
import initContactsForm from './components/contacts-form/contacts-form.js';
import initScheduleButtonShowMore from './components/schedule/schedule.js';
import sliceContent from './utils/clipContent.js';

export default () => {
  initEventsSlider();
  initEventSlider();
  initDetails('[data-entity="event-preview"]', '[data-entity="event-detail"]');
  initDetails('[data-entity="anons-preview"]', '[data-entity="anons-detail"]');
  initCollapse();
  initContactsForm();
  initScheduleButtonShowMore();
  sliceContent('.anons-card__descr');
};
