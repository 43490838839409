import './select.scss';
import Select from './classes/Select';

const initSelect = (containerSelector) => {
  const selectsElements = Array.from(document.querySelectorAll(containerSelector));

  selectsElements.map((selectsElement) => {
    const select = new Select(selectsElement);
    return select;
  });

  return selectsElements;
};

export default initSelect;
