import './detail.scss';

const selectors = {
  opening: '[data-entity="detail-opening"]',
  closing: '[data-entity="detail-closing"]',
};

const renderEventCard = (eventCardVisibilityState, elements) => {
  const {
    page,
    pageOverlay,
    eventCard,
  } = elements;

  if (eventCardVisibilityState === 'hidden') {
    eventCard.classList.remove('detail_shown');
    page.classList.remove('page_locked');
    pageOverlay.classList.remove('page__overlay_shown');
    document.documentElement.style.paddingRight = '0px';
  }

  if (eventCardVisibilityState === 'shown') {
    eventCard.classList.add('detail_shown');
    page.classList.add('page_locked');
    pageOverlay.classList.add('page__overlay_shown');
    document.documentElement.style.paddingRight = '17px';
  }
};

const initEventsCard = (previewSelector, detailSelector) => {
  const eventsPreviews = Array.from(document.querySelectorAll(previewSelector));
  const eventsDetails = Array.from(document.querySelectorAll(detailSelector));
  const page = document.querySelector('.page');
  const pageOverlay = page.querySelector('.page__overlay');

  eventsPreviews.forEach((eventsPreview, index) => {
    const openButton = eventsPreview.querySelector(selectors.opening);
    const eventCard = eventsDetails[index];
    const closeButton = eventCard.querySelector(selectors.closing);

    let eventCardVisibilityState = 'hidden';

    openButton.addEventListener('click', () => {
      eventCardVisibilityState = 'shown';
      renderEventCard(eventCardVisibilityState, {
        page,
        pageOverlay,
        eventCard,
      });
    });

    closeButton.addEventListener('click', () => {
      eventCardVisibilityState = 'hidden';
      renderEventCard(eventCardVisibilityState, {
        page,
        pageOverlay,
        eventCard,
      });
    });

    pageOverlay.addEventListener('click', () => {
      eventCardVisibilityState = 'hidden';
      renderEventCard(eventCardVisibilityState, {
        page,
        pageOverlay,
        eventCard,
      });
    });
  });
};

export default initEventsCard;
