/* eslint-disable import/no-unresolved */
import Swiper, { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import './event-slider.scss';

const initEventSlider = () => {
  const sliderElement = document.querySelector('.event-slider');
  const pagination = sliderElement.querySelector('.event-slider__pagination');

  const slider = new Swiper(sliderElement, {
    slidesPerView: 1,
    spaceBetween: 16,

    modules: [Pagination],
    wrapperClass: 'event-slider__wrapper',
    slideClass: 'event-slider__slide',
    slideActiveClass: 'event-slider__slide_active',
    slidePrevClass: 'event-slider__slide_prev',
    slideNextClass: 'event-slider__slide_next',

    pagination: {
      el: pagination,
      bulletClass: 'event-slider__bullet',
      bulletActiveClass: 'event-slider__bullet_active',
    },
  });

  return slider;
};

export default initEventSlider;
