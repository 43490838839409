import './collapse.scss';

const render = (collapseBody, bodyVisibilityState) => {
  if (bodyVisibilityState === 'shown') {
    collapseBody.classList.add('collapse__body_shown');
  }

  if (bodyVisibilityState === 'hidden') {
    collapseBody.classList.remove('collapse__body_shown');
  }
};

const initCollapse = () => {
  const collapseElements = Array.from(document.querySelectorAll('.collapse'));

  collapseElements.forEach((collapseElement) => {
    const collapseToggler = collapseElement.querySelector('.collapse__toggler');
    const collapseBody = collapseElement.querySelector('.collapse__body');
    let bodyVisibilityState = 'hidden';

    collapseToggler.addEventListener('click', () => {
      bodyVisibilityState = bodyVisibilityState === 'hidden' ? 'shown' : 'hidden';
      render(collapseBody, bodyVisibilityState);
    });
  });
};

export default initCollapse;
