const sliceContent = (selector) => {
  const elements = Array.from(document.querySelectorAll(selector));
  elements.forEach((element) => {
    const text = element.textContent;
    const maxLength = +element.dataset.maxLength;
    const slicedText = text.slice(0, maxLength);

    element.textContent = `${slicedText}...`;
  });
};

export default sliceContent;
