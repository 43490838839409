import './schedule.scss';

const buttonShowMore = {
  defaultText: 'Показать еще',
  activeText: 'Скрыть',
};

const render = ({ hiddenItems, button }, hiddenItemsVisibilityState) => {
  if (hiddenItemsVisibilityState === 'hidden') {
    button.textContent = buttonShowMore.defaultText;
    hiddenItems.forEach((hiddenItem) => {
      hiddenItem.classList.remove('schedule__item_shown');
    });
  }

  if (hiddenItemsVisibilityState === 'shown') {
    button.textContent = buttonShowMore.activeText;
    hiddenItems.forEach((hiddenItem) => {
      hiddenItem.classList.add('schedule__item_shown');
    });
  }
};

const initScheduleButtonShowMore = () => {
  const hiddenItems = Array.from(document.querySelectorAll('.schedule__item_hidden'));
  const button = document.querySelector('.schedule__more');

  let hiddenItemsVisibilityState = 'hidden';

  button.addEventListener('click', () => {
    hiddenItemsVisibilityState = hiddenItemsVisibilityState === 'hidden' ? 'shown' : 'hidden';
    render(
      {
        hiddenItems,
        button,
      },
      hiddenItemsVisibilityState,
    );
  });
};

export default initScheduleButtonShowMore;
