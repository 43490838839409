/* eslint-disable no-param-reassign */
import './contacts-form.scss';
import '../field/field.js';
import '../textarea/textarea.js';
import '../button/button.js';
import '../link/link.js';
import initSelect from '../select/select.js';

const getFormElements = (form) => {
  const elements = {
    name: {},
    email: {},
    message: {},
  };

  elements.name.container = form.querySelector('[data-field="name"]');
  elements.name.field = elements.name.container.querySelector('.field__input');
  elements.name.label = elements.name.container.querySelector('.field__label');

  elements.email.container = form.querySelector('[data-field="email"]');
  elements.email.field = elements.email.container.querySelector('.field__input');
  elements.email.label = elements.email.container.querySelector('.field__label');

  elements.message.container = form.querySelector('[data-field="message"]');
  elements.message.field = elements.message.container.querySelector('.textarea__input');
  elements.message.label = elements.message.container.querySelector('.textarea__label');

  elements.submitButton = form.querySelector('.contacts-form__submit');

  return elements;
};

const renderFieldError = (label, { validationState }, { labelHasErrorClassName }) => {
  if (validationState === 'invalid' || validationState === 'idle') {
    label.classList.add(labelHasErrorClassName);
  }

  if (validationState === 'valid') {
    label.classList.remove(labelHasErrorClassName);
  }
};

const renderForm = (elements, sendingProcessState) => {
  const {
    form,
    submitButton,
    formMessageSuccess,
    formMessageFailed,
  } = elements;

  if (sendingProcessState === 'processing') {
    submitButton.disabled = true;
  }

  if (sendingProcessState === 'finished') {
    submitButton.disabled = false;
    form.remove();
    formMessageFailed.classList.remove('contacts-form__message_shown');
    formMessageSuccess.classList.add('contacts-form__message_shown');
  }

  if (sendingProcessState === 'failed') {
    submitButton.disabled = false;
    formMessageFailed.classList.add('contacts-form__message_shown');
  }
};

const validateField = (elements, state, params) => {
  const {
    field,
  } = elements;
  const { minLength, regexp } = params;

  const fieldValue = field.value;

  if (minLength) {
    if (fieldValue < minLength) {
      state.validationState = 'invalid';
    } else {
      state.validationState = 'valid';
    }
  }

  if (regexp) {
    if (!regexp.test(fieldValue)) {
      state.validationState = 'invalid';
    } else {
      state.validationState = 'valid';
    }
  }
};

const getValidatingFormProcessState = (formControlsState) => {
  const formControlsStateList = Object.values(formControlsState);
  const validFields = formControlsStateList.filter(({ validationState }) => validationState === 'valid');

  if (validFields.length === formControlsStateList.length) {
    return 'valid';
  }

  return 'invalid';
};

const initContactsForm = () => {
  const form = document.querySelector('[data-form="contacts"]');
  const formMessageSuccess = document.querySelector('[data-message="form-contacts"]');
  const formMessageFailed = document.querySelector('[data-error="form-contacts"]');

  const {
    name,
    email,
    message,
    submitButton,
  } = getFormElements(form);

  const state = {
    formControls: {
      name: {
        validationState: 'idle',
      },
      email: {
        validationState: 'idle',
      },
      message: {
        validationState: 'idle',
      },
    },
    validatingProcessState: 'idle',
    sendingProcessState: 'idle',
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();

    state.validatingProcessState = getValidatingFormProcessState(state.formControls);

    renderFieldError(
      name.label,
      state.formControls.name,
      { labelHasErrorClassName: 'field__label_error' },
    );

    renderFieldError(
      email.label,
      state.formControls.email,
      { labelHasErrorClassName: 'field__label_error' },
    );

    renderFieldError(
      message.label,
      state.formControls.message,
      { labelHasErrorClassName: 'textarea__label_error' },
    );

    if (state.validatingProcessState === 'valid') {
      state.sendingProcessState = 'processing';
      renderForm(
        {
          form,
          submitButton,
          formMessageSuccess,
          formMessageFailed,
        },
        state.sendingProcessState,
      );

      const sendingResult = new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          state.sendingProcessState = 'finished';
          renderForm(
            {
              form,
              submitButton,
              formMessageSuccess,
              formMessageFailed,
            },
            state.sendingProcessState,
          );
        }, 500);
      });

      sendingResult.catch(() => {
        state.sendingProcessState = 'failed';
        renderForm(
          {
            form,
            submitButton,
            formMessageSuccess,
            formMessageFailed,
          },
          state.sendingProcessState,
        );
      });
    }
  };

  form.addEventListener('submit', formSubmitHandler.bind(this));

  name.field.addEventListener('change', () => {
    validateField(
      name,
      state.formControls.name,
      {
        regexp: /^[а-яА-ЯёЁ']{2,}$/,
      },
    );

    renderFieldError(
      name.label,
      state.formControls.name,
      { labelHasErrorClassName: 'field__label_error' },
    );
  });

  email.field.addEventListener('change', () => {
    validateField(
      email,
      state.formControls.email,
      {
        regexp: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
      },
    );

    renderFieldError(
      email.label,
      state.formControls.email,
      { labelHasErrorClassName: 'field__label_error' },
    );
  });

  message.field.addEventListener('change', () => {
    validateField(
      message,
      state.formControls.message,
      {
        minLength: 10,
      },
    );

    renderFieldError(
      message.label,
      state.formControls.message,
      { labelHasErrorClassName: 'textarea__label_error' },
    );
  });

  initSelect('.select');
};

export default initContactsForm;
