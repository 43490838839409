/* eslint-disable import/no-unresolved */
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './events-slider.scss';

const initEventsSlider = () => {
  const sliderElement = document.querySelector('.events-slider');
  const pagination = sliderElement.querySelector('.events-slider__pagination');
  const navPrev = sliderElement.querySelector('.events-slider__prev');
  const navNext = sliderElement.querySelector('.events-slider__next');

  const slider = new Swiper('.events-slider', {
    slidesPerView: 1,
    spaceBetween: 16,

    modules: [Navigation, Pagination],
    wrapperClass: 'events-slider__wrapper',
    slideClass: 'events-slider__slide',
    slideActiveClass: 'events-slider__slide_active',
    slidePrevClass: 'events-slider__slide_prev',
    slideNextClass: 'events-slider__slide_next',

    pagination: {
      el: pagination,
      bulletClass: 'events-slider__bullet',
      bulletActiveClass: 'events-slider__bullet_active',
    },

    navigation: {
      prevEl: navPrev,
      nextEl: navNext,

      disabledClass: 'events-slider__nav_disabled',
    },

    breakpoints: {
      480: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 16,
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 16,
      },
    },
  });

  return slider;
};

export default initEventsSlider;
